<template>
    <div class="add-comic page-container">
        <div class="add-comic-steps">
            <el-steps :active="active" finish-status="success">
                <el-step title="新建漫画"></el-step>
                <el-step title="新增章节"></el-step>
                <el-step title="编辑章节"></el-step>
                <el-step title="审核"></el-step>
            </el-steps>
        </div>
        <div class="comic-dispatch-line"></div>
        <div class="add-comic-content" v-scrollbar.y>
            <!-- <keep-alive> -->
                <router-view :active.sync="active"></router-view>
            <!-- </keep-alive> -->
        </div>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-12 20:00:22
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-16 17:41:19
 * @Description: 漫画管理-新增漫画
 * @Company: 成都二次元动漫
 */
export default {
    data() {
        return {
            active: 0
        };
    }
};
</script>

<style lang="scss">
    .add-comic{
        &-steps{
            width: 600px;
            height: 55px;
            margin: 0 auto;
        }
        &-content{
            height: calc(100% - 100px);
            position: relative;
            overflow-y: auto;
        }
    }
</style>
